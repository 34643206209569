<template>
  <div>
    <div class="head-box">
      <img src="@/assets/login-logo.png" class="login-logo" alt />
      <div class="menu-box">
        <a href="http://www.haitao-tech.com/" target="_blank">官方网站</a>
        <a href="http://www.haitao-tech.com/about/index.html" target="_blank">联系我们</a>
      </div>
    </div>
    <div class="login-container">
      <transition name="zoom" appear>
        <div class="wrap-login-form">
          <p class="form-title">注册</p>
          <a-form-model
            style="width: auto"
            ref="formRef"
            :model="form"
            @submit.native.prevent
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            labelAlign="left"
            :rules="rules"
          >
            <a-form-model-item label prop="loginName">
              <a-input :maxLength="11" v-model="form.loginName" placeholder="手机号"></a-input>
            </a-form-model-item>
            <a-form-model-item label prop="smsValidCode">
              <sms-code
                :type="0"
                :phone="form.loginName"
                v-model="form.smsValidCode"
                @isGeted="(res)=>{isGetSms = res}"
              ></sms-code>
            </a-form-model-item>
            <a-form-model-item label prop="loginPwd">
              <a-input v-model="form.loginPwd" type="password" placeholder="请输入密码"></a-input>
            </a-form-model-item>
            <a-form-model-item label prop="reLoginPwd">
              <a-input v-model="form.reLoginPwd" type="password" placeholder="再次输入密码"></a-input>
            </a-form-model-item>
          </a-form-model>
          <div>
            <a-button
              type="primary"
              class="big-button"
              @click="handleSubmit"
              :loading="submitting"
            >注册</a-button>
            <div class="register-row">
              已有账号？
              <a-button type="link" style="padding:0" @click="goLogin">去登录</a-button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { user } from '@/api'
import { reactive, toRefs, computed } from '@vue/composition-api'
import { phoneValidate, pwdValidate } from '@/utils/validator'
export default {
  name: 'AppRegister',
  setup (props, { root }) {
    const rePwdValidate = (rule, value, callback) => {
      if (value) {
        if (value !== state.form.loginPwd) {
          callback(new Error('两次密码输入不一致'))
        } else {
          callback()
        }
      }
    }
    const labelCol = { span: 0 }
    const wrapperCol = { span: 24 }
    const rules = {
      loginName: [
        { required: true, message: '请填写手机号', trigger: 'change' },
        { validator: phoneValidate, trigger: 'change' }
      ],
      smsValidCode: [
        { required: true, message: '请填写短信验证码', trigger: 'change' }
      ],
      loginPwd: [
        { required: true, message: '请填写密码', trigger: 'change' },
        { validator: pwdValidate, trigger: 'change' }
      ],
      reLoginPwd: [
        { required: true, message: '请填写重复密码', trigger: 'change' },
        { validator: rePwdValidate, trigger: 'change' }
      ]
    }
    const state = reactive({
      form: {},
      submitting: false,
      interval: null,
      formRef: null,
      isGetSms: false
    })
    const redirect = computed(() => {
      return decodeURIComponent(
        (root.$route.query && root.$route.query.redirect) || '/'
      )
    })
    function goLogin () {
      root.$router.replace('/login')
    }
    function handleSubmit () {
      if (state.submitting) return
      state.formRef.validate(async valid => {
        if (valid) {
          if (!state.isGetSms) return root.$message.error('请先获取短信验证码')
          state.submitting = true
          let { code, msg } = await user.register({
            loginName: state.form.loginName,
            loginPwd: state.form.loginPwd,
            smsValidCode: state.form.smsValidCode
          })
          state.submitting = false
          if (code === '00000') {
            root.$message.success('注册成功')
            root.$router.replace('/login')
          } else {
            root.$message.error(msg || '注册失败')
          }
        }
      })
    }
    return {
      rules,
      labelCol,
      wrapperCol,
      redirect,
      ...toRefs(state),
      goLogin,
      handleSubmit
    }
  }
}
</script>

<style lang="less" scoped>
.head-box {
  height: 110px;
  width: 1200px;
  padding-top: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .login-logo {
    height: 48px;
    object-fit: contain;
  }
  .menu-box {
    display: flex;
    align-items: center;
    a {
      display: block;
      border-right: 1px solid #ddd;
      padding: 0 16px;
      line-height: 16px;
    }
    a:last-child {
      border: none;
    }
  }
}
::v-deep .sms-btn {
  line-height: 40px;
  margin: 0;
}

.register-row {
  margin-top: 12px;
  text-align: right;
}

.login-container {
  min-height: calc(100vh - 110px);
  position: relative;
  @keyframes be-real {
    to {
      filter: none;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("../assets/login-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -1;
    animation: be-real 0.8s 0.3s ease forwards;
  }
}
.wrap-login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @bg-color: #fff;
  position: absolute;
  top: 40px;
  z-index: 1;
  width: 420px;
  right: 8vw;
  box-sizing: border-box;
  background-color: @bg-color;
  border-radius: 8px;
  padding: 16px 40px;
  text-align: center;
  height: 500px;
  @keyframes spin {
    from {
      opacity: 0;
    }
    to {
      transform: rotate(2turn);
    }
  }
  .form-title {
    font-size: 28px;
    padding-top: 14px;
  }
  ::v-deep .ant-input {
    border-radius: 2px;
    height: 40px;
    line-height: 40px;
  }
  ::v-deep .ant-form-explain {
    text-align: left;
  }
}
.big-button {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  font-size: 20px;
}
</style>
