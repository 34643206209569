// 供应商认证状态
export const shopAuthStates = [
  { name: '未认证', value: 0, color: '#999' },
  { name: '已认证', value: 1, color: '#52c41a' },
  { name: '待审核', value: 2, color: '#ff9400' },
  { name: '认证失败', value: 3, color: '#ff4d4f' }
]

// 商品状态
export const productStates = [
  { name: '正常', value: 1 },
  { name: '已下架', value: 2 },
  { name: '已禁用', value: 3 },
  { name: '已售空', value: 4 }
]

// 税率
export const taxRates = [
  { name: '0%', value: '0.00' },
  { name: '3%', value: '0.03' },
  { name: '4%', value: '0.04' },
  { name: '6%', value: '0.06' },
  { name: '13%', value: '0.13' },
  { name: '17%', value: '0.17' }
]

// 订单状态
export const orderStates = [
  { name: '待付款', value: '0' },
  { name: '待发货', value: '1' },
  { name: '已发货', value: '2' },
  { name: '已完成', value: '3' },
  { name: '已关闭', value: '4' },
  { name: '待成团', value: '5' },
  { name: '待核销', value: '6' }
]

// 支付方式
export const payModes = [
  { name: '账户余额', value: '10' },
  { name: '支付宝', value: '100' },
  { name: '银联', value: '200' },
  { name: '微信支付', value: '300' },
  { name: '微信公众号', value: '500' },
  { name: '块钱', value: '600' },
  { name: '银行卡代扣', value: '700' },
  { name: '线下支付', value: '800' },
  { name: '混合支付', value: '900' },
]

// 快递状态
export const deliveryStatus = [
  { name: '在途中', value: '1' },
  { name: '正在派件', value: '2' },
  { name: '已签收', value: '3' },
  { name: '派送失败', value: '4' },
  { name: '疑难件', value: '5' },
  { name: '退件签收', value: '6' }
]

// 拼团活动
export const groupActivityStatus = [
  { name: '已停用', value: -1 },
  { name: '未开始', value: 0 },
  { name: '进行中', value: 1 },
  { name: '已失效', value: 2 }
]

// 拼团活动
export const bargainActivityStatus = [
  { name: '未开始', value: 0 },
  { name: '进行中', value: 1 },
  { name: '已结束', value: 2 }
]

// 秒杀活动
export const seckillActivityStatus = [
  { name: '已结束', value: 0 },
  { name: '未开始', value: 1 },
  { name: '进行中', value: 2 }
]

// 活动类型
export const activityTypes = [
  { name: '拼团', value: 1 },
  { name: '秒杀', value: 2 },
  { name: '砍价', value: 3 }
]

// 订单类型
export const orderTypes = [
  { name: '普通订单', value: 1 },
  { name: '团购订单', value: 2 },
  { name: '秒杀订单', value: 3 },
  { name: '砍价订单', value: 4 },
  { name: '兑换订单', value: 5 },
  { name: '服务订单', value: 6 },
  { name: '到店消费', value: 7 },
  { name: '电子券', value: 8 },
  { name: '电子券兑换', value: 9 },
  { name: '充值卡', value: 10 }
]

// 退款退货状态
export const orderReturnStates = [
  {
    name: '待审核',
    orderBtn: '买家发起维权',
    value: 0
  }, {
    name: '已完成',
    orderBtn: '维权完成',
    value: 1
  }, {
    name: '已拒绝',
    orderBtn: '卖家拒绝维权',
    value: -1
  }, {
    name: '已取消',
    orderBtn: '买家取消维权',
    value: -2
  }, {
    name: '待买家退货',
    orderBtn: '等待买家退货',
    value: 2
  }, {
    name: '等待商家确认收货并退款',
    orderBtn: '买家发货',
    value: 3
  }
]

// 代金券变动类型
export const changeTypeOptions = [
  { name: '充值', value: 1 },
  { name: '消费', value: 2 },
  { name: '消费退还', value: 3 }
]

// 代金券充值支付方式
export const rechargeTypeOptions = [
  { name: '转账', value: 1 },
  { name: '微信', value: 2 },
  { name: '支付宝', value: 3 }
]

// 商品标签
export const productTags = [
  { label: '随时退', value: '1' },
  { label: '过期退', value: '2' },
  { label: '免预约', value: '3' },
]

// 结算类型
export const settleTypes = [
  { name: '供应商收入', value: 0 },
  { name: '经销商收入', value: 1 },
  // { name: '发展员工收入', value: 2 },
]

// 结算交易类型
export const settleTransactionTypes = [
  { name: '入账', value: 0 },
  { name: '退款', value: 1 },
]

// 结算状态
export class SettleState {
  constructor (value) {
    this._value = value
  }

  get value () {
    return this._value
  }

  get name () {
    return SettleState.allStates.find(x => x.value === this.value).name
  }

  get canCheck () {
    return this.value === 1
  }

  get canSettle () {
    return this.value === 2
  }

  equals (s) {
    if (!(s instanceof SettleState)) {
      throw new Error('should pass a instance of SettleState')
    }
    return s.value === this.value
  }

  static get allStates () {
    return [
      { name: '不可结算', value: 0 },
      { name: '待核对', value: 1 },
      { name: '待打款', value: 2 },
      { name: '已打款', value: 3 },
      { name: '审核失败', value: 4 },
    ]
  }
}

// 发票类型
export const invoiceTypes = [
  { name: '推广费发票', value: 0 },
  { name: '消费者发票', value: 1 },
  { name: '佣金发票', value: 2 },
]

// 开票状态
export const invoiceStates = [
  { name: '待开票', value: 0 },
  { name: '待开票', value: 1 },
  { name: '已开票', value: 2 },
]

// 推广费开票状态
export const serviceInvoiceStates = [
  { name: '待开票', value: 0 },
  { name: '审核失败', value: 1 },
  { name: '已开票', value: 2 },
]

// 推客添加方式
export const sharerSource = [
  { name: '微信扫码', value: 1 },
  { name: '小程序申请', value: 2 },
  { name: '后台导入', value: 3 },
  { name: '公众号', value: 4 },
  { name: '其他', value: 5 },
]
// 推客 状态
export const sharerState = [
  { name: '审核中', value: 0, color: '#ff9400' },
  { name: '正常', value: 1, color: '#52c41a' },
  { name: '禁用', value: 2, color: '#ff4d4f' },
  { name: '拒绝', value: 3, color: '#ff4d4f' },
  { name: '已解绑', value: 4, color: '#666' },
]

// 收益类型
export const incomeType = [
  { name: '自购', value: 1 },
  { name: '分享', value: 2 }
]

// 收益状态
export const settlementState = [
  { name: '不可结算', value: 0 },
  { name: '可结算', value: -1 },
  { name: '已结算', value: 1 }
]

// 收益状态
export const withdrawState = [
  { name: '待审核', value: 0, color: '#ff9400' },
  { name: '提现成功', value: 1, color: '#52c41a' },
  { name: '审核拒绝', value: -1, color: '#ff4d4f' },
  { name: '提现失败', value: -2, color: '#ff4d4f' },
]
