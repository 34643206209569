import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/agent',
  component: AppLayout,
  children: [
    {
      path: 'team_level',
      component: () => import('@/views/agent/PageTeamLevelManagement.vue'),
      meta: { title: '团队等级管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'team',
      component: () => import('@/views/agent/PageTeamManagement.vue'),
      meta: { title: '团队管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'commission_log',
      component: () => import('@/views/agent/PageUserCommissionChangeLog.vue'),
      meta: { title: '佣金变动情况', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'withdraw',
      component: () => import('@/views/agent/PageWithdraw.vue'),
      meta: { title: '提现审核', noClose: false, showInMenuFlag: true }
    }
  ]
}
