import { service } from '@/utils/request'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  getTeamLevelList (payload) {
    return service.get('/agent/team/level/list', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  saveTeamLevel (payload) {
    return service.post('/agent/team/level/save', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  deleteTeamLevel (payload) {
    return service.postForm('/agent/team/level/delete', payload)
  },
  getTeamPage (payload) {
    return service.get('/agent/team/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getTeamInfoData () {
    return service.get('/agent/team/info', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  getCommissionChangeLog (payload) {
    return service.get('/userCommission/pageChangeLog', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  usersByLevel (payload) {
    return service.get('/agent/team/usersByLevel', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  changeUserLevel (payload) {
    return service.postForm('/agent/team/updateLevel', payload)
  }
}
