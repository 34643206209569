import OSS from 'ali-oss'
import { service } from '@/utils/request'

export default function ossUpload (files) {
  return new Promise(async (resolve, reject) => {
    try {
      const config = await getConfig()
      const client = new OSS({
        region: 'oss-cn-qingdao',
        bucket: process.env.VUE_APP_BUCKET,
        ...config
      })
      // 上传多张图片待测试
      if (Array.isArray(files)) {
        const promiseAll = files.map(x => client.put(getObjectKey(x.name), x))
        const arrResult = await Promise.all(promiseAll)
        const uploadSuccess = arrResult.filter((x, i) => {
          x.fileLocalName = files[i].name
          return x.res.status === 200 && x.res.statusCode === 200
        })
        if (uploadSuccess.length) {
          resolve(uploadSuccess.map(x => {
            return {
              url: x.name,
              name: x.fileLocalName
            }
          }))
        } else {
          reject(new Error('上传失败'))
        }
      } else {
        const { res, name } = await client.put(getObjectKey(files.name), files)
        if (res.status === 200 && res.statusCode === 200) {
          resolve(name)
        } else {
          reject(new Error('上传失败'))
        }
      }
    } catch (e) {
      console.error('e', e)
      reject(e)
    }
  })
}

function getObjectKey (fileName) {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const timestampReverse = String(date.getTime()).split('').reverse().join('')
  return `upload/${year}/${month}/${day}/${randomString()}${timestampReverse}.${fileName.substring(fileName.lastIndexOf('.') + 1)}`
}

async function getConfig () {
  const configCookie = getCookie('oss')
  if (configCookie) {
    const [accessKeyId, accessKeySecret, stsToken] = configCookie.split(',')
    return {
      accessKeyId,
      accessKeySecret,
      stsToken
    }
  } else {
    const { code, data } = await service.get('/oss/token')
    if (code === '00000') {
      setCookie('oss', `${data.accessKeyId},${data.accessKeySecret},${data.securityToken}`, data.expiration)
      return {
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        stsToken: data.securityToken
      }
    } else {
      throw new Error('oss配置参数获取失败')
    }
  }
}

function setCookie (name, value, expires) {
  const exp = new Date(expires)
  document.cookie = `${name}=${escape(value)};expires=${exp.toGMTString()};path=/`
}

function getCookie (name) {
  let result = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)')
  return result ? unescape(result.pop()) : ''
}

function randomString (length = 4) {
  let result = ''
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}
