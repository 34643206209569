<template>
  <div>
    <a-skeleton v-if="loading" />
    <div v-else-if="orderInfo">
      <div class="sku-item">
        <base-img
          class="goods-img"
          width="60"
          height="60"
          oss-style="60_60"
          :src="orderInfo.mainPicPath"
        />
        <div class="info">
          <div class="sku-name">订单编号：{{orderInfo.orderNo}}</div>
          <div class="name">{{orderInfo.productName}}</div>
        </div>
        <div class="info-extra">
          <div class="price">¥{{orderInfo.realAmount}}</div>
          <div class="count-down">{{countDown}}</div>
        </div>
      </div>
      <div class="section">
        <div class="title">
          银行转账<span class="sub-title">（目前仅支持银行转帐）</span>
        </div>
        <div class="card-wrapper">
          <ul class="value" v-if="orderInfo.card">
            <li>姓名：{{orderInfo.card.holderName}}</li>
            <li>银行：{{orderInfo.card.bankBranchName}}</li>
            <li>卡号：{{orderInfo.card.bankCardNo}}</li>
          </ul>
        </div>
      </div>
      <div class="section">
        <div class="title">
          支付凭证<span class="sub-title">（上传银行转账成功截图）</span>
        </div>
        <div>
          <upload-img :file-list.sync="fileList" :max="1" />
        </div>
      </div>
      <div class="phones">
        供货商电话：{{orderInfo.serviceTel}}
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, shallowRef, watch, computed } from '@vue/composition-api'
import { wrapAwait } from '@/utils/tools'
import { goods } from '@/api'
import moment from 'moment'
import UploadImg from '@/components/UploadImg'
import { message } from 'ant-design-vue'

export default {
  name: 'ModalBatchPay',
  components: {
    UploadImg,
  },
  props: {
    orderNo: String,
  },
  setup (props) {
    onMounted(() => {
      watch(() => props.orderNo, (value, oldValue) => {
        if (value !== oldValue && value) {
          endTime.value = null
          fileList.value = []
          fetchOrderInfo()
        }
      }, { immediate: true })
    })

    const loading = ref(false)
    const orderInfo = ref(null)
    let endTime = shallowRef(null)
    async function fetchOrderInfo () {
      if (!props.orderNo) return
      loading.value = true
      const [err, data] = await wrapAwait(goods.getBatchPayInfo(props.orderNo))
      loading.value = false
      if (!err) {
        orderInfo.value = data
        endTime.value = moment(data.endDate)
      }
    }

    const time = shallowRef(moment())
    let timer
    onMounted(() => {
      timer = setInterval(() => {
        time.value = moment()
      }, 1000)
    })
    onUnmounted(() => {
      clearInterval(timer)
      timer = null
    })

    const countDown = computed(() => {
      if (time.value && endTime.value) {
        return time.value.isBefore(endTime.value) ? `请在${time.value.to(endTime.value).slice(0, -1)}内完成支付` : '已过期'
      } else {
        return '请在3天内完成支付'
      }
    })

    const fileList = ref([])
    async function submit () {
      if (fileList.value.length === 0) {
        message.warning('请上传支付凭证')
        throw new Error('支付凭证为空')
      }
      const [err] = await wrapAwait(goods.batchPay({
        orderId: props.orderNo,
        proofPics: fileList.value,
      }))
      if (err) {
        message.error(err.message || '提交失败')
        throw new Error(err)
      }
    }

    return {
      orderInfo,
      loading,
      countDown,
      fileList,
      submit,
    }
  }
}
</script>

<style scoped>
.sku-item {
  display: flex;
  align-items: center;
}
.sku-item .info {
  padding-left: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 auto;
}
.sku-item .goods-img {
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
}
.info .name {
  font-size: 14px;
  color: #333;
  padding-top: 5px;
}
.info .sku-name {
  font-size: 12px;
  color: #999;
}
.info-extra {
  text-align: right;
}
.info-extra .price {
  color: #dc0024;
  font-size: 18px;
}
.info-extra .count-down {
  font-size: 12px;
  color: #999;
}
.section {
  padding: 10px 0;
}
.section .title {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}
.section .sub-title {
  color: #999;
  font-size: 12px;
}
.card-wrapper {
  display: flex;
}
.card-wrapper .field {
  padding-right: 20px;
}
.card-wrapper .value {
  font-size: 12px;
}
.phones {
  font-size: 12px;
  color: #999;
}
</style>
